@import "../common";

.section-faq {
  background: rgb(81,69,122);
  .section-header {
    background-image: url(../assets/Main_Landing_Page/Website_Assets/headline-faq.svg); }

  .faq-container {
    width: 80vw;
    margin-left: 10vw;
    padding-top: 18vh;
    padding-bottom: 5vh;

    @include desktop {
      width: 50vw;
      margin-left: 40vw;
      padding-top: 8vh; }

    color: white;
    .faq-item {
      background-image: url(../assets/Main_Landing_Page/Website_Assets/separator.svg);
      background-size: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      &:last-child {
        border-bottom: none; }

      .faq-header {
        cursor: pointer;
        font-weight: 600;
        font-size: 1.7vh;
        padding: 2vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-text {}

        .arrow {
          display: block;
          height: 2vh;
          width: 2vh;
          background-image: url(../assets/Main_Landing_Page/Website_Assets/icon-arrow-down.svg);
          background-repeat: no-repeat;
          transition: transform .1s linear; } }

      .faq-content {
        overflow: hidden;
        transition: all .05s linear;
        margin: 0;
        opacity: 0;
        padding: 0;
        font-size: 0;

        a {
          color: white; } }

      &.active {
        .faq-content {
          opacity: 1;
          padding: 0 1.7vh 1.7vh 1.7vh;
          font-size: 1.5vh;
          font-weight: 100; }
        .arrow {
          transform: rotate(180deg); } } } } }

