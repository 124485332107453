@import "../common";

.section-mission {
  .section-header {
    //background-image: url(../assets/headline-mission-vision.svg)
    background-image: url(../assets/Main_Landing_Page/Website_Assets/headline-team.svg);
    top: 5vh;
    width: 80vw;
    height: 15vh;
    @include desktop {
      top: 15vh;
      width: 50vh;
      height: 15vh; } }

  .mission-section-content {
    margin-top: 23vh;
    padding-bottom: 5vh;
    @include desktop {
      margin-top: 35vh; }

    .mission-block {
      margin: 0 auto;
      width: 80vw;
      background-image: url(../assets/box-red-dark2-tall.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 80vh;

      @include desktop {
        background-image: url(../assets/box-red-dark2.png);
        height: 50vh; }
      position: relative;

      .text {
        z-index: 11;
        position: absolute;
        color: white;
        width: 80%;
        left: 10%;
        margin: 1vh auto;
        font-size: 1.7vh;
        line-height: 2.2vh;
        font-weight: 400;
        padding-top: 5vh;
        a {
          color: #d8c1bf;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            color: white; } }
        @include desktop {
          padding-top: 0;
          padding-left: 2vw;
          left: 5%;
          width: 60%;
          top: 50%;
          transform: translateY(-50%); } }

      .guitar {
        position: absolute;
        background-image: url(../assets/guitar.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 80vw;
        height: 65vw;
        right: -20vw;
        bottom: -10vh;
        z-index: 10;

        @include desktop {
          width: 50vh;
          height: 50vh;
          right: -15vw;
          top: -9vh; } } } } }

