@import "./../common.sass";

.section-head {
  .header {
    position: absolute;
    z-index: 10;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    height: 60px;
    font-weight: 600;
    justify-content: space-between;
    @include desktop {
      width: 80%;
      left: 10%;
      top: 30px;
      justify-content: normal; }

    .logo {
      background: url(../assets/tru/logo-complete.png) no-repeat 5%;
      width: 50%;
      margin-left: 10%;
      height: 60px;
      background-size: contain;

      @include desktop {
        margin-left: 0;
        width: 30%;
        height: 60px; } }

    .menu-trigger {
      background: url(../assets/Main_Landing_Page/Website_Assets/burger-menu.svg) no-repeat;
      width: 30px;
      height: 30px;
      margin-right: 10%;
      margin-top: 20px;

      @include desktop {
        display: none; } }

    .menu, .icons {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%; } }

    .mobile-menu {
      background: #91362e;
      color: white;
      position: absolute;
      width: 100%;
      left: 0;
      top: 60px;
      ul {
        list-style: none; }
      a {
        color: white;
        line-height: 30px;
        text-decoration: none; }
      transition: height .2s linear;
      height: 0;
      overflow: hidden;
      &.open {
        height: 240px; } }

    .menu {
      display: none;
      @include desktop {
        display: block; }
      flex-grow: 1;
      ul {
        li {
          a {
            font-size: .9em;
            padding: 0 20px;
            text-transform: uppercase;
            color: white;
            text-decoration: none;
            transition: color .3s;
            &.active, &:hover {
              color: #91362e; } } } } }

    .icons {
      display: none;
      @include desktop {
        display: block; }
      margin-left: 30px;
      ul {
        li {
          margin-left: 15px;
          a {
            img {
              display: block;
              width: 30px;
              transition: all .3s;
              &:hover {
                transform: scale(1.2); } } } } } } }

  .scroll-down {
    position: absolute;
    z-index: 10;
    background: url(../assets/icon-arrow-scroll-down.svg) no-repeat;
    bottom: 0;
    cursor: pointer;
    transition: bottom;
    animation: scroll-down-bouncing 2s linear infinite;
    width: 10vw;
    height: 10vw;
    left: 45vw;
    @include desktop {
      width: 3vw;
      height: 3vw;
      left: 48vw; } }

  .bottom-mint-button {
    position: absolute;
    display: inline-block;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }

  .button {
    cursor: pointer;
    text-decoration: none;
    border: none;
    color: white;
    display: inline-block;
    background-color: transparent;
    background-size: 100% 100%;
    height: 42px;
    font-size: 14px;
    padding: 14px 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    white-space: nowrap;
    outline: none;
    transition: all .4s;
    &.green {
      background-image: url(../assets/box-green-wide.png);
      -webkit-box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2);
      -moz-box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2);
      box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2);
      filter: drop-shadow(0 8px 8px rgba(23, 190, 83, .3));
      &:hover {
        filter: drop-shadow(0 8px 8px rgba(23, 190, 83, .6)); } }

    .icon {
      margin-left: 10px; }
    &.icon-left {
      .icon {
        margin-left: 0;
        margin-right: 10px; } } }

  .icon {
    &:before {
      display: inline-block;
      width: 12px;
      height: 12px;
      content: " ";
      vertical-align: -1px; }
    &.arrow-right:before {
      background-image: url(../assets/icon-arrow-right.svg); } }

  @keyframes scroll-down-bouncing {
    0% {
      bottom: 0; }

    50% {
      bottom: -1vh;
      @include desktop {
        bottom: -.5vw; } }

    100% {
      bottom: 0; } }


  .buttons {
    display: flex;
    flex-wrap: wrap;

    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    .button, a.button {
      margin-top: 2vh;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        transform: scale(1.1); }
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;


      @include desktop {
        &:last-child {
          margin-left: 5vw; } }
      text-align: center;
      text-transform: none;
      width: 210px;
      height: 70px;
      letter-spacing: normal;
      font-weight: 300;
      h3 {
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 2vh; } } } }
