@import "../common";

.section-bandroom {
  background-image: url(../assets/bandroom-box-purple-darkest-wide.png);
  background-size: 100% 100%;

  padding: 15vh 0 0 0;
  @include desktop {
    padding: 8vw 0 0 0;
    background-image: url(../assets/bandroom-box-purple-darkest.png); }

  .section-header {
    position: static;
    background-image: url(../assets/headline-bandroom.svg);
    margin-left: 10vw;
    width: 80vw; }

  .recent-gigs-header {
    background-image: url(../assets/headline-recent-gigs.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: 10vw;
    height: 10vh;
    width: 60vw;
    @include desktop {
      width: 25vw; } }

  .top-text {
    width: 80%;
    margin: 2vh auto;
    text-align: center;
    a {
      font-weight: 400;
      color: #C9BDF4;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: white; } }
    .bold {
      color: white;
      font-weight: 400;
      margin-bottom: .8vh;
      @include desktop {
        margin-bottom: 1.2vh; } }
    font-weight: 100;
    color: rgb(240, 240, 240);
    @include desktop {
      margin-top: 3vh;
      font-size: 2.7vh;
      margin-bottom: 3vh; } }

  .video {
    width: 80%;
    margin: 2vh auto;
    video {
      width: 100%; } }

  .events {
    @include desktop {
      display: flex;
      width: 80%;
      margin: 0 auto; }
    .event {
      width: 80%;
      margin: 4vh auto;
      color: white;
      position: relative;
      background-image: url(../assets/box-purple-dark.png);
      background-size: 100% 100%;
      padding: 6vh 4vh 5vh;
      @include desktop {
        width: 30%; }
      .date {
        background-image: url(../assets/Main_Landing_Page/Website_Assets/box-purple.png);
        background-size: 100% 100%;
        text-align: center;
        margin: 1vh 0 1vh 0;
        padding: 1vh 0 1.3vh;
        line-height: 2vh;
        font-weight: 300;
        font-size: 1.5vh; }
      .header {
        margin: 1vh 0;
        font-weight: 400;
        font-size: 3.5vh; }
      .text {
        color: rgb(230, 230, 230);
        font-size: 2.3vh;
        margin: 1vh 0;
        @include desktop {
          font-size: 2vh;
          margin: 1vh 0; } }

      .image {
        margin: 2vh 0 1vh 0;
        img {
          display: block;
          width: 100%; } }

      .button {
        margin-top: 4vh;
        @include desktop {
          margin-top: 2vh; }
        a {
          display: flex;
          height: 5vh;
          color: white;
          text-decoration: none;
          justify-content: space-between;
          align-items: center;
          font-size: 1.4vh;
          font-weight: 600;
          padding-left: 2vw;
          background: url(../assets/Main_Landing_Page/Website_Assets/box-red.png) no-repeat;
          background-size: 100% 100%;
          //width: 30vw
          @include desktop {
 }            //width: 10vw

          .arrow {
            height: 2vh;
            width: 2vh;
            margin-right: 2vh;
            background: url(../assets/Main_Landing_Page/Website_Assets/icon-arrow-right.svg) no-repeat; } } } } }

  .recent-gigs {
    @include desktop {
      display: flex;
      width: 80%;
      margin: 0 auto; }
    .event {
      width: 80%;
      margin: 4vh auto;
      color: white;
      position: relative;
      .event-video {
        position: relative;
        padding: 0;
        margin: 0;
        video {
          display: block;
          width: 100%; }
        .play-button {
          position: absolute;
          top: 90%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-image: url(../assets/bandroom-recent-gigs-play.svg);
          background-size: 100%;
          background-repeat: no-repeat;
          width: 20%;
          img {
            display: block;
            width: 100%; } } }
      @include desktop {
        width: 30%; }

      .footer {
        background-image: url(../assets/bandroom-box-purple-dark-recent-gigs.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 2vh 0;
        .header {
          font-size: 2vh;
          font-weight: 400;
          text-align: center;
          @include desktop {
            font-size: 3.5vh;
            margin: 2vh 0; } }
        .date {
          color: rgb(200,200,200);
          text-align: center;
          font-weight: 200;
          font-size: 1.5vh;
          margin: 2vh 0;
          @include desktop {
            font-size: 2vh; } } } } } }

