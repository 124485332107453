@import "./../common.sass";

.ava-container {
  width: 90%;
  margin: 5vh auto;
  padding: 5vh;
  background-image: url(../assets/calculator/calculator-bg-mobile.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  color: white;

  @include desktop {
    position: relative;
    background-image: url(../assets/calculator/calculator-bg.png);
    background-size: 100% 100%;
    padding: 5vw 8vw;
    justify-content: space-between; }

  h2 {
    font-weight: 400;
    font-size: 3vh;
    margin: 0; }
  h4 {
    font-weight: 100;
    margin: 3vh 0;
    font-size: 1.5vh;
    opacity: .8; }

  .text {
    width: 100%;
    padding: 4vw;
    h2 {
      font-size: 2.2vh; }
    @include desktop {
      margin: 0;
      width: 50%; } }

  .player-container {
    margin-left: -19vw;
    width: 110vw;

    @include desktop {
      margin: 0;
      position: absolute;
      top: 1vh;
      right: 0;
      width: 50%; } } }
