@import "./../common.sass";

.calculator-container {
  width: 90%;
  margin: 5vh auto 0;
  padding: 5vh;
  background-image: url(../assets/boxes/focus-box-mobile-bg-02.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  color: white;

  @include desktop {
    background-image: url(../assets/boxes/focus-box-bg-02.png);
    background-size: 100% 100%;
    padding: 5vw 10vw;
    display: flex;
    justify-content: space-between; }

  h2 {
    font-weight: 400;
    font-size: 3vh;
    margin: 0; }
  h4 {
    font-weight: 100;
    margin: 3vh 0;
    font-size: 1.5vh;
    opacity: .8; }

  .col {
    width: 100%;
    &.calculator {
      @include desktop {
        width: 50%;
        padding-top: 4vh; }

      .images-slider {
        position: relative;
        .images-container {
          overflow: hidden;
          .images-set {
            transition: margin-left .3s;
            white-space: nowrap;
            .image {
              display: inline-block;
              margin-right: 5vw;
              width: 19vw;
              @include desktop {
                width: 6vw;
                margin-right: 1vw; }
              .image-wrapper {
                position: relative;
                background-image: url(../assets/calculator/calculator-legend-enabled-02.svg);
                background-size: 92%;
                background-position: center center;
                background-repeat: no-repeat;
                img {
                  display: block;
                  width: 100%; }
                .number {
                  position: absolute;
                  font-size: 1.5vh;
                  left: .8vh;
                  top: .8vh; } }

              &.active {
                background: url(../assets/calculator/calculator-legend-active2-02.svg) 100% no-repeat;
                background-size: contain; } } } }

        .arrow {
          position: absolute;
          z-index: 10;
          background-size: contain;
          background-repeat: no-repeat;
          width: 7vw;
          height: 7vw;
          top: 6vw;
          @include desktop {
            width: 3vw;
            height: 3vw;
            top: 2vw; }

          &.left {
            left: -8vw;
            background-image: url(../assets/calculator/calculator-arrow-left.svg);
            @include desktop {
              left: -3vw; } }

          &.right {
            right: -9vw;
            background-image: url(../assets/calculator/calculator-arrow-right.svg);
            @include desktop {
              right: -3vw; } } } }

      .slider-wrapper {
        .slider {
          background-image: url(../assets/calculator/calculator-range-slider-bg-02.svg);
          background-size: contain;
          background-repeat: no-repeat;
          height: 5vw;
          width: 100%;

          .slider-thumb {
            transition: left .3s;
            outline: none;
            margin-top: -1.8vw;
            background-image: url(../assets/calculator/calculator-range-slider-button-02.svg);
            background-size: contain;
            background-repeat: no-repeat;
            height: 8vw;
            width: 8vw;
            @include desktop {
              height: 4vh;
              width: 4vh;
              margin-top: -1vh; } } } } }


    &.money {
      position: relative;
      margin-bottom: 7vh;
      @include desktop {
        margin-bottom: 0; }
      img {
        display: block;
        width: 100%; }
      .actor-image {
        position: absolute;
        width: 70%;
        bottom: -10%; }
      .money-image {
        margin-left: 20%; }
      .sound-check {
        position: absolute;
        border: none;
        background: none;
        background-image: url(../assets/rarity-button-soundcheck.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        z-index: 3;
        outline: none;
        width: 50vw;
        height: calc(50vw*.3);
        bottom: -15vw;
        right: 50%;
        transform: translateX(50%); }

      @include desktop {
        width: 50%;
        .actor-image, .money-image {
          position: absolute; }
        .money-image {
          z-index: 1;
          margin: 0;
          bottom: 10%;
          right: -10%;
          width: 60%; }
        .actor-image {
          z-index: 2;
          width: 60%;
          bottom: 2%;
          left: 10%; }
        .sound-check {
          width: 13vw;
          height: calc(13vw*.3);
          bottom: 5%;
          right: 0; } } } } }
