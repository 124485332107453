@import "../common";

.section-metaverse {
  .blocks {
    width: 80vw;
    margin: 0 auto; }

  .sub {
    @include desktop {
      display: flex;
      justify-content: center; } }

  .block {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;

    padding: 5%;

    width: 100%;
    margin: 3vh auto;

    @include desktop {
      width: 40%;
      margin: 0;
      padding: 3vw; }


    &.main {
      width: 100%;
      background-image: url("../assets/box-darkgrey-tall.png");
      h2 {
        font-size: 3vh; }
      @include desktop {
        position: relative;
        padding: 3vw 5vw;
        h2 {
          margin-top: .8em;
          font-size: 5vh; }
        background-image: url("../assets/box-darkgrey-wide.png"); }
      .main-text {
        @include desktop {
          width: 60%; } }
      .main-image {
        background-image: url("../assets/metaverse-tru.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 5vh auto 0;
        width: 50vw;
        height: 50vw;
        @include desktop {
          position: absolute;
          width: 23vw;
          height: 23vw;
          bottom: -2vh;
          right: 0; } } }

    &.purple {
      background-image: url("../assets/Main_Landing_Page/Website_Assets/box-purple-tall.png");
      @include desktop {
        background-image: url("../assets/Main_Landing_Page/Website_Assets/box-purple.png"); } }
    &.taupe {
      background-image: url("../assets/Main_Landing_Page/Website_Assets/box-taupe-tall.png");
      @include desktop {
        background-image: url("../assets/Main_Landing_Page/Website_Assets/box-taupe.png"); } }

    h2 {
      font-size: 2.7vh;
      font-weight: 500; }
    h3 {
      font-size: 2vh;
      font-weight: 400; }
    .text {
      font-size: 1.7vh;
      font-weight: 100;
      line-height: 1.25; }

    ul {
      margin: 0 0 2vh;
      padding: 0;
      list-style: none;
      li {
        background-image: url(../assets/separator-grey.svg);
        background-size: 100%;
        background-position: bottom;
        background-repeat: no-repeat;

        &:last-child {
          background-image: none; }

        white-space: nowrap;

        .icon {
          display: inline-block;
          background-image: url(../assets/Main_Landing_Page/Website_Assets/icon-arrow-down.svg);
          background-repeat: no-repeat;
          width: 2vh;
          height: 2vh;
          margin-right: 1.5vh; }
        .text {
          font-size: 1.8vh;
          line-height: 4.5vh;
          font-weight: 400; } } } } }


