

.scene {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  user-select: none;
  canvas {
    position: absolute; } }
