@import "../common";

.section-slider {
  .section-header-wrapper {
    width: 80vw;
    margin: 0 auto; }
  .section-header {
    position: static;
    background-image: url(../assets/headline-mission.svg);
    top: 5vh;
    width: 80vw;
    height: 15vh;
    margin-top: 8vh;
    @include desktop {
      top: 15vh;
      width: 50vh;
      height: 15vh; } }


  .slider-wrapper {
    overflow: hidden;
    border-radius: 20%;

    @include desktop {
      width: 70%;
      margin: 0 auto; } } }

