@keyframes glow {
  from {
    transform: scale(1); }

  50% {
    transform: scale(1.1); }

  to {
    transform: scale(1); } }

.audio-visual-art-player {
  .audio-player-container {
    position: relative;
    background: url(./../assets/player/background.png);
    background-size: 100%;
    background-repeat: no-repeat;

    .button {
      position: absolute;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      &.play-button {
        background-image: url(./../assets/player/play.png); }

      &.pause-button {
        background-image: url(./../assets/player/pause.png); }

      &.glow {
        transform-origin: center;
        animation: 3s infinite glow; } } } }
