@import "../common";

.section-legends-slider {
  margin-top: 10vh;

  .section-header {
    position: static;
    background-image: url(../assets/legends-slider/headline-legends.svg);
    margin-left: 10vw;
    width: 70%; }

  .slider {
    position: relative;
    overflow: hidden;

    .slider-button {
      position: absolute;
      width: 10vw;
      height: 10vw;
      background-size: contain;
      background-repeat: no-repeat;
      top: 30%;
      z-index: 10;
      @include desktop {
        width: 3vw;
        height: 3vw; }

      &.slider-button-left {
        background-image: url(../assets/legends-slider/legend-slider-arrow-left.svg);
        left: 2vw; }

      &.slider-button-right {
        background-image: url(../assets/legends-slider/legend-slider-arrow-right.svg);
        background-position: right;
        right: 2vw; } }

    .slider-items {
      margin: 2vh 0;
      display: flex;
      width: 100%;
      transition: all .4s;

      .slider-item {
        margin-right: 5vw;
        @include desktop {
          margin-right: 3vw; }
        .image {
          img {
            margin: 0 auto;
            display: block;
            width: 30vw;
            @include desktop {
              width: 12vw; } } }

        .spotlight {
          background-image: url(../assets/legends-slider/legend-slider-tru-spotlight.png);
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 60%; }

        .label-container {
          text-align: center;
          margin-top: 3vh;
          .label {
            background-image: url(../assets/legends-slider/legend-slider-box-anthracite-wide.png);
            background-size: 100% 100%;
            color: white;
            font-weight: 400;
            font-size: 2vh;
            display: inline-block;
            padding: .5vw 3vw;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; } }

        transition: all .4s;

        &.inactive {
          opacity: 0.1; }

        &.active {
          opacity: 1;
          .image {
            background-image: url(../assets/legends-slider/legend-slider-box-anthracite.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center; } } } } } }


