@import "../common";

.section-hall-of-fame {
  .section-header {
    position: static;
    background-image: url(../assets/halloffame/headline-hall-of-fame.svg);
    margin-left: 10vw;
    margin-top: 5vh;
    width: 80vw; }
  .hall-of-fame-section-content {
    margin: 2vh auto;
    width: 80vw;
    .artists {
      margin-top: 10vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .artist {
        width: 80%;
        margin-bottom: 5vh;
        @include desktop {
          width: 30%;
          &:nth-child(odd) {
            padding-top: 5vh; } }

        .photo {
          width: 50%;
          margin: 0 auto;
          position: relative;

          img {
            display: block;
            width: 100%;
            border-radius: 50%; }

          &:before {
            width: 30%;
            height: 30%;
            position: absolute;
            bottom: -15%;
            left: 0;
            content: " ";
            background: url(../assets/halloffame/hall-of-fame-quote-left.svg) 100% no-repeat; }
          &:after {
            width: 30%;
            height: 30%;
            position: absolute;
            top: -15%;
            right: 0;
            content: " ";
            background: url(../assets/halloffame/hall-of-fame-quote-right.svg) 100% no-repeat; } }

        .name {
          text-align: center;
          font-size: 3.5vh;
          font-weight: 400;
          color: white;
          margin: 3vh 0; }

        .text {
          text-align: center;
          font-size: 2vh;
          color: rgb(200,200,200); }

        .mint-button {
          text-align: center;
          margin-top: 2vh;
          a {
            font-weight: 500;
            color: white;
            text-decoration: none;
            display: inline-block; } }

        .buttons {
          display: flex;
          justify-content: center;
          width: 50%;
          margin: 2vh auto;
          a {
            display: block;
            &.audius {
              width: 10%;
              margin: .2vh 5%; }
            &.soundcloud {
              width: 15%;
              margin: .5vh 5%; }
            &.website {
              width: 10%;
              margin: .3vh 5%; } } } } }

    .bottom-button {
      text-align: center;
      a {
        display: inline-block;
        margin: 0 auto;
        height: 5vh;
        color: white;
        text-decoration: none;
        justify-content: space-between;
        align-items: center;
        font-size: 1.4vh;
        font-weight: 600;
        padding-left: 2vw;
        background: url(../assets/Main_Landing_Page/Website_Assets/box-red.png) no-repeat;
        background-size: 100% 100%;
        line-height: 5vh;

        .arrow {
          vertical-align: -.5vh;
          display: inline-block;
          height: 2vh;
          width: 2vh;
          margin-right: 2vh;
          margin-left: 2vh;
          background: url(../assets/Main_Landing_Page/Website_Assets/icon-arrow-right.svg) no-repeat; } } } } }
