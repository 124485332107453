@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;1,200&display=swap');
@import "common.sass";

* {
  box-sizing: border-box;
 }  //user-select: none

html, body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  //font-family: 'Montserrat', sans-serif
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  background: #191919; }

h2 {
  font-size: 3vh;
  line-height: 3.5vh; }
.sections {
  .section {
    position: relative;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: $bg-color;
    overflow: hidden;

    &.auto {
      height: auto; }

    &.film-separator {
      height: 30vh;
      background: url(./assets/Main_Landing_Page/Website_Assets/the_way.png) $bg-color;
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      @include desktop {
        background-position: center;
        height: 55vh;
        background-size: contain; } } }

  .section-header {
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
    position: absolute;
    left: 10vw;
    height: 10vh;
    top: 5vh;
    width: 50vw;

    @include desktop {
      top: 10vh;
      width: 20vw; } }


  .badge {
    position: absolute;
    top: 95vh;
    right: 2vw;
    width: 25vw;
    height: 25vw;
    z-index: 10;
    background-image: url(./assets/badge-musicians.svg);
    background-size: contain;
    transform: rotate(15deg);
    @include desktop {
      width: 10vw;
      height: 10vw;
      top: 90vh;
      right: 20vw; } } }


