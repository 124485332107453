@import "../common";

.section-rarity {
  position: relative;
  padding-top: 5vw;
  .top-bg {
    background-color: #2E7691;
    @include desktop {
      background-color: transparent;
      background-image: url(../assets/boxes/big-box-bg-02.svg);
      background-repeat: no-repeat; }
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9;
    height: 140vh;
    @include desktop {
      height: 80vh; } }

  .about-content {
    position: relative;
    z-index: 10; }

  .section-header {
    position: static;
    background-image: url(../assets/labels/rarity.svg);
    margin-left: 10vw; }

  .text {
    z-index: 10;
    color: white;
    margin: 2vh auto;
    width: 80vw;

    @include desktop {
      margin: -10vh 0 0 40vw;
      width: 50vw; }

    font-weight: 100;

    .text-header {
      font-size: 3vh;
      font-weight: 600;
      margin-bottom: 2vh; }

    p {
      font-size: 2vh;
      margin: 1vh 0; } }

  .blocks {
    margin: 0 auto;
    width: 80vw;
    display: grid;
    padding: 1rem;
    grid-gap: 1rem;
    z-index: 11;
    grid-template-columns: repeat(1, 1fr);

    @include desktop {
      margin: 13vh auto 0;
      grid-template-columns: repeat(4, 1fr); }

    .block {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 30vh;
      color: white;
      text-align: center;
      .icon {
        background-size: contain;
        background-repeat: no-repeat;
        height: 11vh;
        margin: 4vh 0 0;
        background-position: center; }
      .block-header {
        font-weight: 600;
        font-size: 4vh; }
      .block-comment {
        font-weight: 100;
        font-size: 1.8vh;
        margin: 2vh; }

      &.ppl {
        background-image: url(../assets/Main_Landing_Page/Website_Assets/box-purple.svg);
        .icon {
          background-image: url(../assets/Main_Landing_Page/assets/icon-rockstarz.png); } }
      &.eth {
        background-image: url(../assets/Main_Landing_Page/Website_Assets/box-red.svg);
        .icon {
          background-image: url(../assets/Main_Landing_Page/assets/icon-ethereum.png); } }
      &.rights {
        background-image: url(../assets/Main_Landing_Page/Website_Assets/box-grey.svg);
        .icon {
          background-image: url(../assets/Main_Landing_Page/assets/icon-copyright.png); } }
      &.chart {
        background-image: url(../assets/Main_Landing_Page/Website_Assets/box-black.svg);
        .icon {
          background-image: url(../assets/Main_Landing_Page/assets/icon-charts.png); } } } } }

.signer-video-container {
  background-image: url(../assets/section-bg-worldtour.png);
  background-size: 100%;
  background-repeat: no-repeat;

  @include desktop {
    background-position-y: 100%;
    margin-top: -15vh;
    padding-top: 15vh; }

  .signer-video-wrapper {
    width: 80vw;
    position: relative;

    @include desktop {
      width: 30vw; }

    margin: 0 auto;
    padding: 10vh 0;

    video {
      width: 80%;
      margin: 0 auto;
      display: block; }

    .description {
      background-image: url(../assets/Main_Landing_Page/Website_Assets/box-black-stretch.png);
      background-size: 100% 100%;

      @include desktop {
        position: absolute;
        width: 20vw;
        top: 50%;
        left: -50%; }


      .text {
        padding: 3vh;
        color: white;
        h3 {
          margin: 0 0 1.2vh 0;
          font-size: 2.2vh; }
        font-size: 1.7vh; } } } }
