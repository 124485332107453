@import "../common";

.footer-section {
  width: 100vw;
  height: 30vh;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding-top: 0;
  background-repeat: no-repeat;
  background-image: url(../assets/footer-bg-purple-tall.png);
  background-size: 100% 100%;
  justify-content: center;
  @include desktop {
    margin-top: 25vh;
    background-image: url(../assets/footer-bg-purple.png);
    justify-content: space-between;
    padding-top: 5vh;
    flex-wrap: nowrap;
    background-size: 100% 100%; }

  ul {
    flex-grow: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%; }


  .logo {
    background: url(../assets/Main_Landing_Page/Website_Assets/logo-vertical.svg) no-repeat center;
    height: 10vh;
    width: 100vw;
    @include desktop {
      background: url(../assets/Main_Landing_Page/Website_Assets/logo.svg) no-repeat 5%;
      height: 5vh;
      width: 30vw;
      margin-left: 3vw; } }

  .icons {
    margin-top: 2vh;
    @include desktop {
      height: 100%;
      margin-right: 3vh; }
    ul {
      li {
        @include desktop {
          margin-bottom: 3vh; }
        margin-right: 15px;
        a {
          img {
            display: block;
            width: 3vh; } } } } }

  .unicorn {
    display: none;
    position: absolute;
    background: url(../assets/eth-guy.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include desktop {
      display: block;
      width: 40vh;
      height: 40vh;
      left: 50%;
      transform: translateX(-50%);
      bottom: 3vw; } }

  .press {
    width: 100%;
    font-weight: 500;
    font-size: 2vh;
    text-align: center;
    a {
      color: white; }

    @include desktop {
      width: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-60%);
      bottom: 0.5vw; } }

  .discord-text {
    z-index: 11;
    padding-top: 4vh;
    color: white;
    font-size: 2.5vh;
    font-weight: 600;
    padding-left: 5vw;
    width: 70vw;
    @include desktop {
      padding-left: 10vw; }

    .discord-button {
      margin-top: 2vh;
      a {
        display: flex;
        height: 5vh;
        color: white;
        text-decoration: none;
        justify-content: space-between;
        align-items: center;
        font-size: 1.4vh;
        font-weight: 600;
        padding-left: 2vw;
        background: url(../assets/Main_Landing_Page/Website_Assets/box-red.png) no-repeat;
        background-size: 100% 100%;
        width: 30vw;
        @include desktop {
          width: 10vw; }

        .arrow {
          height: 2vh;
          width: 2vh;
          margin-right: 2vh;
          background: url(../assets/Main_Landing_Page/Website_Assets/icon-arrow-right.svg) no-repeat; } } } } }

