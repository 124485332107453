

@mixin desktop {
  @media (min-width: 480px) {
    @content; } }

$bg-color: rgb(25,25,25);


.button {
  cursor: pointer;
  text-decoration: none;
  border: none;
  color: white;
  display: inline-block;
  background-color: transparent;
  background-size: 100% 100%;
  height: 40px;
  font-size: 12px;
  padding: 14px 20px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
  outline: none;
  transition: all .4s;
  &.green {
    background-image: url(./assets/box-green-wide.png);
    -webkit-box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2);
    -moz-box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2);
    box-shadow: 0 8px 10px 2px rgba(23, 190, 83, 0.2);
    filter: drop-shadow(0 8px 8px rgba(23, 190, 83, .3));
    &:hover {
      filter: drop-shadow(0 8px 8px rgba(23, 190, 83, .6)); } }
  &.red {
    //background-image: url(./assets/box-red-widest.png)
    -webkit-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2);
    -moz-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2);
    box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2);
    filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .3));
    &:hover {
      filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .6)); } }
  &.purple {
    background-image: url(./assets/box-purple-wide.png);
    -webkit-box-shadow: 0 8px 10px 2px rgba(121, 106, 175, 0.2);
    -moz-box-shadow: 0 8px 10px 2px rgba(121, 106, 175, 0.2);
    box-shadow: 0 8px 10px 2px rgba(121, 106, 175, 0.2);
    filter: drop-shadow(0 8px 8px rgba(121, 106, 175, .3));
    &:hover {
      filter: drop-shadow(0 8px 8px rgba(101, 86, 155, .6)); } }
  &.dark-red {
    //background-image: url(./assets/box-dark-red-widest.png)
    //-webkit-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2)
    -moz-box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2);
    box-shadow: 0 8px 10px 2px rgba(205, 84, 68, 0.2);
    filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .3));
    &:hover {
      filter: drop-shadow(0 8px 8px rgba(205, 84, 68, .6)); } }


  .icon {
    margin-left: 10px; }
  &.icon-left {
    .icon {
      margin-left: 0;
      margin-right: 10px; } } }

.icon {
  &:before {
    display: inline-block;
    width: 12px;
    height: 12px;
    content: " ";
    vertical-align: -1px; }
  &.arrow-left:before {
 }    //background-image: url(./assets/icon-arrow-left.svg)
  &.arrow-right:before {
    background-image: url(./assets/icon-arrow-right.svg); } }
