@import "../common";

.section-team {
  .section-header {
    background-image: url(../assets/Main_Landing_Page/Website_Assets/headline-team.svg); }
  .team-container {
    display: flex;
    flex-wrap: wrap;
    margin: 5vh auto 0;
    width: 80vw;
    .item {
      a {
        font-weight: 400;
        color: #9c9c9c;
        text-decoration: none;
        font-size: 1.25vh;
        &:hover {
          text-decoration: underline;
          color: white; } }

      width: 80vw;
      margin: 5vw 0 0 0;

      @include desktop {
        width: 35vw;
        margin: 5vw 5vw 0 0;
        display: flex; }

      align-items: center;
      justify-content: space-between;

      .content {
        color: white;
        font-weight: 100;
        font-size: 1.4vh;

        .header {
          font-weight: 600;
          font-size: 2vh;
 }          //margin-bottom: 1vh
        .role {
          font-weight: 400;
          font-size: 1.5vh;
          margin-bottom: 1vh; }
        .description {
          font-weight: 200;
          font-size: 1.5vh;
          margin-bottom: 1vh; }
        width: 80vw;
        padding: 5vw 0 2vw 3vw;
        @include desktop {
          padding: 0 0 0 5vw;
          width: 23vw; } }

      .icon {
        flex-grow: 1;
        height: 35vh;
        width: 35vh;
        margin: 0 auto;
        //background: url(../assets/Main_Landing_Page/Website_Assets/box-black.png)
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        @include desktop {
          height: 12vw;
          width: 12vw;
          margin: 0; }

        .img {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat; }

        video {
          display: block;
          width: 100%; }

        .social {
          position: absolute;
          left: -2vh;
          width: 4vh;
          top: 0;
          height: 10vh;
          background: url(../assets/icon-social-bg.svg) no-repeat top center;
          z-index: 1;
          padding-top: .5vh;
          a {
            width: 2.2vh;
            height: 2.2vh;
            margin: .5vh auto;
            display: block;
            background-size: contain;
            &.twitter {
              background-image: url(../assets/icon-social-twitter.svg); }
            &.linkedin {
              background-image: url(../assets/icon-social-linkedin.svg); }
            &.instagram {
              background-image: url(../assets/icon-social-instagram.svg); }
            &.spotify {
              background-image: url(../assets/icon-spotify.png); } }

          @include desktop {
            width: 3vw;
            height: 4vw;
            padding-top: .1vh;
            a {
              width: 1.5vw;
              height: 1.5vw;
              margin: .3vw auto; } } } } } } }
