@import "common";

.header-hall-of-fame-section {
  height: 10vh!important; }

.section-hall-of-fame.page {
  .artist {
    @include desktop {
      width: 25%!important; } }
  .section-header {
    margin: 7vh auto;
    background-position: center; } }
