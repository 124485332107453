@import "./../common.sass";

.timer-wrapper {
  .timer-label {
    z-index: 10;
    position: absolute;
    color: white;
    text-align: center;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 4vh;
    font-weight: 400;
    @include desktop {
      width: 60%;
      font-size: 7vh; } }

  .timer {
    z-index: 10;
    position: absolute;
    color: white;
    text-align: center;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include desktop {
      width: 50%; }
    .col {
      display: inline-block;
      width: 25%;
      .value {
        font-size: 4vh;
        font-weight: 400;
        @include desktop {
          font-size: 7vh; } }
      .label {
        font-size: 2vh;
        font-weight: 300; } } }

  .dates {
    z-index: 10;
    position: absolute;
    color: white;
    text-align: center;
    width: 100%;
    top: 65%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .date {
      background-image: url("./../assets/Main_Landing_Page/Website_Assets/box-black-stretch.png");
      background-size: 100% 100%;
      padding: .5vh 2vw;
      font-size: 1.7vh;
      white-space: nowrap;
      margin-right: 3vw;
      margin-bottom: 1vh; } } }


