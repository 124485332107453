@import "../common";

$bg-brown: rgb(71, 41, 37);

.section-how-to {
  .section-background-header {
    width: 100%;
    background: url(../assets/howto/howto-bg.png);
    background-size: 100% 100%;
    height: 5vh; }
  .section-content-wrapper {
    background: $bg-brown; }
  .section-header {
    position: static;
    height: 10vh;
    @include desktop {
      height: 12vh; }
    margin: 0;
    img {
      display: block;
      height: 100%; } }
  .section-content {
    width: 90vw;
    @include desktop {
      width: 80vw; }

    margin: 0 auto;
    padding: 4vh 0;

    .top-comment {
      color: rgb(222,222,222);
      margin: 4vh 2vh;
      font-size: 1.8vh;
      @include desktop {
         width: 80%; } }

    .howto-list {
      .element {
        margin-bottom: 2vh;
        .element-header {
          color: white;
          display: flex;
          align-items: center;
          cursor: pointer;
          .number {
            background: url(../assets/howto/howto-numbering-bg.svg) 100% no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            width: 6vh;
            height: 6vh;
            flex-shrink: 0;
            font-size: 2.5vh;
            font-weight: 500; }

          .header {
            font-size: 2.5vh;
            font-weight: 500;
            padding: 0 5vh;
            @include desktop {
              white-space: nowrap;
              flex-shrink: 0; } }

          .separator {
            overflow: hidden;
            flex-grow: 1;
            div {
              width: 60vw;
              height: 2vh;
              background: url(../assets/howto/howto-separator.svg) 100% no-repeat;
              display: none;
              @include desktop {
                display: block; } } }

          .toggle {
            flex-shrink: 0;
            padding-left: 3vh;
            div {
              width: 3vh;
              height: 3vh;
              background-image: url(../assets/howto/icon-plus.svg);
              background-size: 100%;
              background-repeat: no-repeat; } } }

        .content {
          color: rgb(200, 200, 200);
          overflow: hidden;
          transition: all .05s linear;
          margin: 0;
          opacity: 0;
          padding: 0;
          font-size: 0;
          a {
            color: inherit; } }

        &.active {
          .toggle div {
            background-image: url(../assets/howto/icon-minus.svg); }
          .content {
            opacity: 1;
            margin: 2vh;
            @include desktop {
              margin: 1vh 0 0 11vh; }
            font-size: 1.7vh;
            line-height: 2.2vh; } } } } } }

